import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Companies = () => {

    const companyData = [
        { id: 1, img: '/img/dest/companies/finance_magnates.svg', name: 'Finance Magnates' },
        { id: 2, img: '/img/dest/companies/forexlive.svg', name: 'ForexLive' },
        { id: 3, img: '/img/dest/companies/cryptocompare.svg', name: 'CryptoCompare' },
        { id: 4, img: '/img/dest/companies/bitcoin.svg', name: 'Bitcoin' },
        { id: 5, img: '/img/dest/companies/themerkle.svg', name: 'The Merkle' },
        { id: 6, img: '/img/dest/companies/the_economic_times.svg', name: 'The Economic Times' },
        { id: 7, img: '/img/dest/companies/advfn.svg', name: 'ADVFN' },
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 10000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        variableWidth: true,
        arrows: false,
    };

    return (
        <div className='wrapper'>
            <Slider {...settings}>
                {
                    companyData?.map((company) => (
                        <div key={company?.id} className='p-4 m-4'>
                            <a href="#" target="_blank" className='cursor-pointer'>
                                <img src={company?.img} alt={company?.name} />
                            </a>
                        </div>
                    ))
                }
            </Slider >
        </div>
    )
}

export default Companies