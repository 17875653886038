import moment from 'moment';
import React from 'react'

export default function Blogs() {

    const items = [
        { id: 1, img: '/img/dest/blog-1.svg', title: 'UX review presentations', description: 'How do you create compelling presentations that wow your colleagues and impress your managers?' },
        { id: 1, img: '/img/dest/blog-2.svg', title: 'Migrating to Linear 101', description: 'Linear helps streamline software projects, sprints, tasks, and bug tracking. Here’s how to get started.' },
        { id: 1, img: '/img/dest/blog-3.svg', title: 'Building your API Stack', description: 'The rise of RESTful APIs has been met by a rise in tools for creating, testing, and managing them.' },
        { id: 1, img: '/img/dest/blog-3.svg', title: 'Building your API Stack', description: 'The rise of RESTful APIs has been met by a rise in tools for creating, testing, and managing them.' },
    ];

    return (
        <div className="full-glow-background-top">
            <div className="glow-content">
                <div className='wrapper md:pb-16 mb-16'>
                    <div className='bg-gradient-to-r from-[#133D56] to-[#6756D4] h-[5px] w-16 mx-auto'></div>
                    <h1 className='h1 mt-5 text-center'>Our Latest Blogs</h1>

                    <div className='sm:grid md:grid-cols-2 lg:grid-cols-4 gap-8 mt-16'>
                        {items?.map((blog) => (
                            <div className='shadow rounded-3xl p-4 cursor-pointer bg-white'>
                                <div>
                                    <img src={blog?.img} className="w-full h-auto" />
                                    <p className='text-lg font-semibold text-[#718096] mt-3'>{moment().format('ll')}</p>
                                    <p className='text-lg font-semibold text-[#1A202C] mt-2'>{blog?.title}</p>
                                    <p className='text-sm line-clamp-3 text-[#718096] mt-2'>{blog?.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
