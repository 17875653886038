import React from 'react'

const HowMuch = () => {
    return (
        <div className="wrapper my-16">
            <div className="bg-gradient-to-r from-[#133D56] to-[#6756D4] rounded-xl sm:grid grid-cols-2 py-12">
                <div className='flex justify-center items-center'>
                    <div>
                        <h2 className="h2 text-white mb-0 mt-2 font-semibold">How much does it cost?</h2>
                        {/* <h2 className="h2 text-white mb-0 font-semibold">It’s FREE</h2> */}
                        <p className='text-[#FFFFFF] text-sm'>We have different plan according to your usage.</p>
                    </div>
                </div>
                <div className='flex justify-center items-center mt-6 sm:mt-0'>
                    <button className='font-bold text-white text-lg rounded-full py-3 px-9 bg-gradient-to-r from-[#D9D9D966] to-[#D9D9D933] border'>Contact us for the details</button>
                </div>
            </div>
        </div >
    )
}

export default HowMuch