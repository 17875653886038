import React, { useState } from 'react'

const Escrow = () => {

    const [type, setType] = useState('trader');

    return (
        <div className="full-glow-background-bottom">
            <div className="glow-content">
                <div className='wrapper my-16 py-16'>
                    <div className='flex justify-center'>
                        <div className='flex items-center justify-center border-2 rounded-full'>
                            <button className={`py-3 px-8 duration-500 font-bold text-lg rounded-full ${type == 'trader' ? 'text-white py-3 px-6 bg-gradient-to-r from-[#133D56] to-[#6756D4]' : 'text-black bg-white'}`} onClick={() => setType('trader')}>CRM Trader</button>
                            <button className={`py-3 px-8 duration-500 font-bold text-lg rounded-full ${type == 'broker' ? 'text-white py-3 px-6 bg-gradient-to-r from-[#133D56] to-[#6756D4]' : 'text-black bg-white'}`} onClick={() => setType('broker')}>CRM Brokers</button>
                        </div>
                    </div>
                    {
                        type == 'trader' ? (
                            <div className='lg:grid grid-cols-3 gap-8 mt-16'>
                                <div>
                                    <div className='mb-5'>
                                        <p className='text-[#1A202C] font-semibold text-2xl'>Live Account Management</p>
                                        <p className='text-[#718096] text-lg leading-6 mt-1'>Monitor balances, open positions, and trading history.</p>
                                    </div>
                                    <div className='mb-5'>
                                        <p className='text-[#1A202C] font-semibold text-2xl'>Deposit/Withdrawal</p>
                                        <p className='text-[#718096] text-lg leading-6 mt-1'>Secure and efficient transactions with multiple payment options.</p>
                                    </div>
                                    <div className='mb-5'>
                                        <p className='text-[#1A202C] font-semibold text-2xl'>Transaction Reports</p>
                                        <p className='text-[#718096] text-lg leading-6 mt-1'>Overview of trading activity, deposits, and withdrawals.</p>
                                    </div>
                                    <div className='mb-5'>
                                        <p className='text-[#1A202C] font-semibold text-2xl'>Admin Support</p>
                                        <p className='text-[#718096] text-lg leading-6 mt-1'>Prompt assistance for account-related issues and queries.</p>
                                    </div>
                                </div>
                                <div className='flex items-center justify-center my-16'>
                                    <img src="/img/dest/crm.svg" className="w-auto h-auto" />
                                </div>
                                <div>
                                    <div className='mb-5'>
                                        <p className='text-[#1A202C] font-semibold text-2xl'>Client Area</p>
                                        <p className='text-[#718096] text-lg leading-6 mt-1'>Manage personal information and stay updated with news.</p>
                                    </div>
                                    <div className='mb-5'>
                                        <p className='text-[#1A202C] font-semibold text-2xl'>Trading Tools</p>
                                        <p className='text-[#718096] text-lg leading-6 mt-1'>Access market analysis, signals, and educational resources.</p>
                                    </div>
                                    <div className='mb-5'>
                                        <p className='text-[#1A202C] font-semibold text-2xl'>Security and Privacy</p>
                                        <p className='text-[#718096] text-lg leading-6 mt-1'>Advanced security measures to protect your information.</p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='lg:grid grid-cols-3 gap-8 mt-16'>
                                <div>
                                    <div className='mb-5'>
                                        <p className='text-[#1A202C] font-semibold text-2xl'>Advanced Dashboard</p>
                                        <p className='text-[#718096] text-lg leading-6 mt-1'>Real-time data and analytics for informed decision-making.</p>
                                    </div>
                                    <div className='mb-5'>
                                        <p className='text-[#1A202C] font-semibold text-2xl'>Client Management</p>
                                        <p className='text-[#718096] text-lg leading-6 mt-1'>Track client activity and preferences for personalized service.</p>
                                    </div>
                                    <div className='mb-5'>
                                        <p className='text-[#1A202C] font-semibold text-2xl'>IB Manager</p>
                                        <p className='text-[#718096] text-lg leading-6 mt-1'>Manage IB relationships and track performance and commissions.</p>
                                    </div>
                                    <div className='mb-5'>
                                        <p className='text-[#1A202C] font-semibold text-2xl'>Business Analytics</p>
                                        <p className='text-[#718096] text-lg leading-6 mt-1'>Monitor metrics and generate detailed reports.</p>
                                    </div>
                                </div>
                                <div className='flex items-center justify-center my-16'>
                                    <img src="/img/dest/crm.svg" className="w-auto h-auto" />
                                </div>
                                <div>
                                    <div className='mb-5'>
                                        <p className='text-[#1A202C] font-semibold text-2xl'>Config Manager</p>
                                        <p className='text-[#718096] text-lg leading-6 mt-1'>Customize CRM settings and workflows to fit your needs.</p>
                                    </div>
                                    <div className='mb-5'>
                                        <p className='text-[#1A202C] font-semibold text-2xl'>Client Support</p>
                                        <p className='text-[#718096] text-lg leading-6 mt-1'>Integrated ticketing and automated responses for quick support.</p>
                                    </div>
                                    <div className='mb-5'>
                                        <p className='text-[#1A202C] font-semibold text-2xl'>IB Management</p>
                                        <p className='text-[#718096] text-lg leading-6 mt-1'>Streamline IB recruitment and reward processes.</p>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </div>
    )
}

export default Escrow