import React from 'react'

const EscrowDifference = () => {
    return (
        <div className="wrapper py-16">
            <div className="md:grid grid-cols-2 gap-8">
                <div className="flex items-center justify-center">
                    <div className='bg-gradient-to-r from-[#F4F9FF] to-[#FFFFFF] ps-8 pt-8 rounded-tl-[68px]'>
                        <h2 className="h2 m-0 font-bold w-4/5">How Neptune CRM is different than other CRM?</h2>
                    </div>
                </div>
                <div className="flex items-center justify-center">
                    <img src="/img/dest/diffrent.svg" className="w-auto sm:h-[700px]" />
                </div>
            </div>
        </div>
    )
}

export default EscrowDifference