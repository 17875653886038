import React from "react";

const WhatIsFinacial = () => {
  return (
    <div className="wrapper md:py-16">
      <div className="md:grid grid-cols-2 gap-8">
        <div className="flex items-center justify-center">
          <img src="/img/dest/forex-work.svg" className="w-auto h-[500px]" />
        </div>
        <div className="flex items-center justify-center">
          <div>
            <h2 className="h2 font-bold">How Forex CRM work?</h2>
            <p className="text-lg text-[#718096]">Introducing Neptune Forex CRM, a comprehensive solution designed specifically for brokers, encompassing a back office, trader’s room, and advanced live monitoring system compatible with MT5, and Vertex platforms. Leveraging our extensive experience in the financial services sector, we understand that in the world of Forex, time is a critical asset. Therefore, we are committed to delivering rapid yet high-quality integration services. We can seamlessly integrate Neptune Forex CRM with your MetaTrader 5, Vertex Trading platform, significantly enhancing your operational efficiency.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatIsFinacial;
