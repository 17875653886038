import React from 'react';
import { PopupModal } from "react-calendly";

const Calendly = ({ open, onClose }) => {

    return (
        <PopupModal
            url="https://calendly.com/neptunefxcrm/neptunefxcrm-demo"
            rootElement={document.getElementById("root")}
            onModalClose={onClose}
            open={open}
            text=""
            textColor="#ffffff"
            color="#00a2ff"
        />
    )
}

export default Calendly