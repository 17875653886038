import React, { useState } from 'react';
import Calendly from '../../calendly/Calendly';

const Intro = () => {
    const [showCalendly, setShowCalendly] = useState(false);

    const handleRequestDemo = () => {
        setShowCalendly(true);
    };
    const handleCloseCalendly = () => {
        setShowCalendly(false);
    };
    return (
        <div className="full-glow-background-top">
            <div className="glow-content">
                <section className="intro my-16">
                    <div className="wrapper">
                        <div className='grid md:grid-cols-2'>
                            <div className='my-auto order-2 md:order-1 mt-16 md:mt-auto'>
                                <h1 className="h2 font-bold">A  robust CRM solution for Forex brokers.</h1>
                                <p className='font-medium'>Designed for forex brokers and traders to ensure secure operations to ensure secure operations.</p>
                                <ul className="breadcrumbs mt-6 uppercase font-medium text-[#21406B]">
                                    <li> Comprehensive</li>
                                    <li>Seamless</li>
                                    <li>Advanced</li>
                                </ul>
                                {/* <a href="#demo-form"> */}
                                    <button onClick={handleRequestDemo} className="font-bold text-white text-lg rounded-full py-3 px-6 bg-gradient-to-r from-[#133D56] to-[#6756D4]">Request a Demo</button>
                                    <Calendly open={showCalendly} onClose={handleCloseCalendly}/>
                                {/* </a> */}
                            </div>
                            <div className='order-1 md:order-2'>
                                <img src='/img/dest/intro_bg.svg' />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Intro