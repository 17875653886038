import React, { useState } from "react";
import Calendly from "../calendly/Calendly";

const Header = () => {
  const [showCalendly, setShowCalendly] = useState(false);

  const handleRequestDemo = () => {
    setShowCalendly(true);
  };
  const handleCloseCalendly = () => {
    setShowCalendly(false);
};
  return (
    <>
      <header className="header border-b-2 bg-white ">
        <div className="wrapper py-4">
          <div className="header__left">
            <img src="/assets/logo/logo.png" className="h-8 md:h-12" alt="logo" />
          </div>
          <div className="flex justify-end header__right gap-5 sm:gap-8">
            <a className="font-semibold items-center gap-3 hidden md:flex" href="mailto:info@neptunefxcrm.com">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-5">
                <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
              </svg>
              <p className="leading-3 text-[16px]">info@neptunefxcrm.com</p>
            </a>
            <button onClick={handleRequestDemo} id="demo" className="font-bold text-white text-xs sm:text-lg rounded-full py-1 sm:py-3 px-2 sm:px-6 bg-gradient-to-r from-[#133D56] to-[#6756D4]">Request a Demo</button>
            <Calendly open={showCalendly} onClose={handleCloseCalendly}/>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
