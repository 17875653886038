import React from 'react';

const CountriesServe = () => {
    const countries = [
        { name: 'Australia', flag: '/assets/images/australia.png' },
        { name: 'Bangladesh', flag: '/assets/images/bangladesh.png' },
        { name: 'Canada', flag: '/assets/images/canada.png' },
        { name: 'Cyprus', flag: '/assets/images/cyprus.png' },
        { name: 'Finland', flag: '/assets/images/finland.png' },
        { name: 'France', flag: '/assets/images/france.png' },
        { name: 'Germany', flag: '/assets/images/germany.png' },
        { name: 'India', flag: '/assets/images/India.png' },
        { name: 'Indonesia', flag: '/assets/images/indonesia.png' },
        { name: 'Ireland', flag: '/assets/images/ireland.png' },
        { name: 'Israel', flag: '/assets/images/israel.png' },
        { name: 'Japan', flag: '/assets/images/japan.png' },
        { name: 'Malaysia', flag: '/assets/images/malaysia.png' },
        { name: 'Netherlands', flag: '/assets/images/netherlands.png' },
        { name: 'Nigeria', flag: '/assets/images/nigeria.png' },
        { name: 'Norway', flag: '/assets/images/norway.png' },
        { name: 'Philippines', flag: '/assets/images/philippines.png' },
        { name: 'Poland', flag: '/assets/images/poland.png' },
        { name: 'Russia', flag: '/assets/images/russia.png' },
        { name: 'Singapore', flag: '/assets/images/singapore.png' },
        { name: 'Southafrica', flag: '/assets/images/south-africa.png' },
        { name: 'Srilanka', flag: '/assets/images/srilanka.png' },
        { name: 'Sweden', flag: '/assets/images/sweden.png' },
        { name: 'Thailand', flag: '/assets/images/thailand.png' },
        { name: 'Turkey', flag: '/assets/images/turkey.png' },
        { name: 'UAE', flag: '/assets/images/united-arab-emirates.png' },
        { name: 'USA', flag: '/assets/images/usa.png' },
        { name: 'Vietnam', flag: '/assets/images/vietnam.png' },

    ];

    return (
        <div className='container mx-auto'>
            <div className='bg-gradient-to-r from-[#133D56] to-[#6756D4] h-[5px] w-16 mx-auto'></div>
            <h1 className="h1 text-5xl mb-10 text-center mt-5">Countries We Serve</h1>
            <div className="grid grid-cols-7 gap-3">
                {countries.map((country, index) => (
                    <div key={index} className="flex items-center px-5 rounded-md border h-16 hover:-translate-y-1 duration-300">
                        <img src={country.flag} alt={country.name} className="w-8 h-8 me-4" />
                        <p className="text-lg font-medium">{country.name}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CountriesServe