import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader (CSS)
import { Carousel } from 'react-responsive-carousel';

const FinacialConnection = () => {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: false,
    };

    return (
        <div className='wrapper md:py-16'>
            <div className='md:grid md:grid-cols-3 gap-8'>
                <div>
                    <div className='bg-gradient-to-r from-[#133D56] to-[#6756D4] h-[5px] w-16 mt-10'></div>
                    <h2 className='h2 mt-5 font-bold'>Neptune CRM Structure</h2>
                    <p className='text-[#718096]'>Introducing Neptune Forex CRM, a comprehensive solution designed specifically for brokers, encompassing a back office, trader’s room, and advanced live.</p>
                </div>
                <div className='md:col-span-2 mt-5 md:mt-0'>
                    <Carousel autoPlay={true} infiniteLoop={true} showArrows={false} showThumbs={false} showStatus={false} className='w-full'>
                        <div>
                            <div className='border-2 rounded-xl pt-4 pb-8 px-8 grid md:grid-cols-2 gap-5'>
                                <div className='flex sm:justify-end justify-start items-center'>
                                    <img src='/img/dest/structure.svg' className='h-[315px] sm:mx-6' />
                                </div>
                                <div className='my-auto lg:ms-8 text-left'>
                                    <p className='text-[#1A202C] text-3xl font-bold my-4'>Forex CRM Admin</p>
                                    <p className='text-lg text-[#718096] mb-2'>1. Advanced Dashboard</p>
                                    <p className='text-lg text-[#718096] mb-2'>2. Client Management</p>
                                    <p className='text-lg text-[#718096] mb-2'>3. IB Manager</p>
                                    <p className='text-lg text-[#718096] mb-2'>4. Business Analytics</p>
                                    <p className='text-lg text-[#718096] mb-2'>5. Config Manager</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='border-2 rounded-xl pt-4 pb-8 px-8 grid md:grid-cols-2 gap-5'>
                                <div className='flex justify-center sm:justify-end items-center'>
                                    <img src='/img/dest/back-office.svg' className='h-[315px] ps-8 sm:mx-6' />
                                </div>
                                <div className='my-auto lg:ms-8 text-left ms-8'>
                                    <p className='text-[#1A202C] text-3xl font-bold my-4'>Back Office</p>
                                    <p className='text-lg text-[#718096] mb-2'>1. Client Support</p>
                                    <p className='text-lg text-[#718096] mb-2'>2. Deposit / Withdrawal</p>
                                    <p className='text-lg text-[#718096] mb-2'>3. Partnership Program</p>
                                    <p className='text-lg text-[#718096] mb-2'>4. Account Manager</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='border-2 rounded-xl pt-4 pb-8 px-8 grid md:grid-cols-2 gap-5'>
                                <div className='flex justify-center sm:justify-end'>
                                    <img src='/img/dest/client-portal.svg' className='h-[315px] w-[315px] sm:mx-6' />
                                </div>
                                <div className='my-auto lg:ms-8 text-left'>
                                    <p className='text-[#1A202C] text-3xl font-bold my-4'>Client Portal</p>
                                    <p className='text-lg text-[#718096] mb-2'>1. Live Account</p>
                                    <p className='text-lg text-[#718096] mb-2'>2. Deposit / Withdrawal</p>
                                    <p className='text-lg text-[#718096] mb-2'>3. Transaction Reports</p>
                                    <p className='text-lg text-[#718096] mb-2'>4. Admin Support</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='border-2 rounded-xl pt-4 pb-8 px-8 grid md:grid-cols-2 gap-5'>
                                <div className='flex justify-center sm:justify-end'>
                                    <img src='/img/dest/ib-dashboard.svg' className='h-[255px] w-[315px] sm:mx-6 my-7' />
                                </div>
                                <div className='my-auto lg:ms-8 text-left'>
                                    <p className='text-[#1A202C] text-3xl font-bold my-4'>IB Dashboard</p>
                                    <p className='text-lg text-[#718096] mb-2'>1. Commission Reports</p>
                                    <p className='text-lg text-[#718096] mb-2'>2. Team Management</p>
                                    <p className='text-lg text-[#718096] mb-2'>3. IB Tree</p>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </div>
            </div>
        </div>
    )
}

export default FinacialConnection