import React from 'react'

const BrokerConnection = () => {

    const steps = [
        {
            id: 1,
            title: 'You leave a request for CRM demo',
            description: 'We handle all aspects of vetting and choosing the right team that you don’t have the time, expertise, or desire to do.',
        },
        {
            id: 2,
            title: 'Our team will arrange Demo',
            description: 'Sprint roadmap is a collective planning effort. Team members collaborate to clarify items and ensure shared understanding.',
        },
        {
            id: 3,
            title: 'Start Onboarding Process',
            description: 'We break monolithic apps into microservices. Decoupling the code allows teams to move faster and more independently.',
        },
        {
            id: 4,
            title: 'Our Team starts Integration process',
            description: 'Standups, weekly demos, and weekly reviews make sure everyone is on the same page and can raise their concerns.',
        },
        {
            id: 5,
            title: 'Testing & Submission',
            description: 'Code reviews before release help detect issues like memory leaks, file leaks, performance signs, and general bad smells.',
        },
        {
            id: 6,
            title: 'Start using CRM',
            description: 'We divide the implementation process into several checkpoints rather than a single deadline.',
        },
    ];

    return (
        <div className="full-glow-background-top">
            <div className="glow-content">
                <div className="wrapper my-16">
                    <div className='bg-gradient-to-r from-[#133D56] to-[#6756D4] h-[5px] w-16 mx-auto'></div>
                    <h1 className='h2 font-bold mt-5 text-center'>How We Works?</h1>

                    <div className='lg:grid md:grid-cols-2 lg:grid-cols-3 gap-6 mt-12'>
                        {
                            steps?.map((step) => (
                                <div className='border-2 px-4 py-8 rounded-xl m-4 bg-white'>
                                    <p className='font-bold line-clamp-1'><span className='text-[#6C57DB]'>#{step?.id} </span>{step?.title}</p>
                                    <p className='text-[#718096] leading-7 text-lg mt-4'>{step?.description}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BrokerConnection