import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import axios from 'axios';
import toast from 'react-hot-toast';

const Feedback = () => {
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', website: '', companyName: '', description: '' });
  const [captchaText, setCaptchaText] = useState('');
  const [userCaptcha, setUserCaptcha] = useState('');

  const generateCaptcha = () => {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let captcha = '';
    for (let i = 0; i < 6; i++) {
      captcha += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setCaptchaText(captcha);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userCaptcha !== captchaText) {
      toast.error("Invalid captcha.!");
      generateCaptcha();
      return;
    }
    try {
      const { data } = await axios.post('https://neptunefxcrm.com/crm-email/crm-demo-req', formData);
      if (data?.success) {
        toast.success(data?.message);
        setFormData({ name: '', email: '', phone: '', website: '', companyName: '', description: '' });
        setUserCaptcha('');
      }
    }
    catch (error) {
    }
  }

  useEffect(() => {
    generateCaptcha();
  }, []);

  return (
    <div className='wrapper my-16 pb-16'>
      <div className='bg-gradient-to-r from-[#133D56] to-[#6756D4] h-[5px] w-16 mx-auto'></div>
      <h1 className='h1 mt-5 text-center'>Looking for a robust Forex CRM?</h1>

      <div id='demo-form' className='bg-[#F9F8FF] rounded-xl mt-16 lg:p-16 p-4 xl:w-3/5 mx-auto'>
        <p className='text-[#1A202C] font-bold text-3xl'>Want a Free Demo?</p>
        <p className='text-[#1A202C] text-lg font-medium mt-2'>Fill in this form or send us an E-mail.</p>

        <form className='w-full' autoComplete='off' onSubmit={handleSubmit}>
          <div className='md:grid grid-cols-2 gap-6 mt-8'>
            <div className='my-5 md:my-0'>
              <Input name='name' value={formData.name}
                onChange={handleInputChange} size="large" className='py-3 ps-4' placeholder="Enter Name" required prefix={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#718096" className="size-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                </svg>}
              />
            </div>
            <div className='my-5 md:my-0'>
              <Input name='email' value={formData.email}
                onChange={handleInputChange} size="large" className='py-3 ps-4' placeholder="Enter Email" required prefix={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#718096" className="size-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                </svg>}
              />
            </div>
            <div className='my-5 md:my-0'>
              <Input name='website' value={formData.website}
                onChange={handleInputChange} size="large" className='py-3 ps-4' placeholder="Company Website" required prefix={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#718096" className="size-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                </svg>}
              />
            </div>
            <div className='my-5 md:my-0'>
              <Input name='phone' value={formData.phone}
                onChange={handleInputChange} size="large" className='py-3 ps-4' placeholder="Phone Number" type='Number' required prefix={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#718096" className="size-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                </svg>}
              />
            </div>
            <div className='md:col-span-2 my-5 md:my-0'>
              <Input name='companyName' value={formData.companyName}
                onChange={handleInputChange} size="large" className='py-3 ps-4' placeholder="Company Name" required prefix={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#718096" className="size-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 18.75h-9m9 0a3 3 0 0 1 3 3h-15a3 3 0 0 1 3-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 0 1-.982-3.172M9.497 14.25a7.454 7.454 0 0 0 .981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 0 0 7.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 0 0 2.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 0 1 2.916.52 6.003 6.003 0 0 1-5.395 4.972m0 0a6.726 6.726 0 0 1-2.749 1.35m0 0a6.772 6.772 0 0 1-3.044 0" />
                </svg>}
              />
            </div>
            <div className='md:col-span-2 my-5 md:my-0'>
              <Input name='description' value={formData.description}
                onChange={handleInputChange} size="large" className='py-3 ps-4' placeholder="Enter Description" required
                prefix={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#718096" className="size-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                </svg>}
              />
            </div>
            <div className='my-5 md:my-0'>
              <Input
                value={userCaptcha}
                onChange={e => setUserCaptcha(e.target.value)}
                size="large" className='py-3 ps-4' placeholder="Enter Capcha" required prefix={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#718096" className="size-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                </svg>}
              />
            </div>
            <div className='flex items-center gap-4'>
              <div className='p-2 border-2 rounded-lg bg-white cursor-pointer' onClick={generateCaptcha}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
              </div>
              <div className='px-4 py-1 border-2 rounded-lg bg-white'>
                <p>{captchaText}</p>
              </div>
            </div>
          </div>
          <div className='flex items-center justify-center mt-12'>
            <button type='submit' className="font-bold text-white text-lg rounded-full py-2 px-16 bg-gradient-to-r from-[#133D56] to-[#6756D4]">Send Message</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Feedback