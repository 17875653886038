import React, { useState } from 'react';
import Calendly from '../../calendly/Calendly';

const JoinUs = () => {
    const [showCalendly, setShowCalendly] = useState(false);

    const handleRequestDemo = () => {
        setShowCalendly(true);
    };
    const handleCloseCalendly = () => {
        setShowCalendly(false);
    };
    return (
        <div className="wrapper my-16">
            <div className="bg-gradient-to-r from-[#133D56] to-[#6756D4] rounded-xl sm:grid grid-cols-2 py-12 gap-5">
                <h3 className=" text-center text-white mb-0 mt-2">Empowering Forex Businesses with Advanced CRM Solutions</h3>
                <div className='flex justify-center mt-6 sm:mt-0'>
                    {/* <a href="#demo-form"> */}
                    <button onClick={handleRequestDemo} className='font-bold text-white text-lg rounded-full py-3 px-9 bg-gradient-to-r from-[#D9D9D966] to-[#D9D9D933] border'>Request a Demo</button>
                    {/* </a> */}
                    <Calendly open={showCalendly} onClose={handleCloseCalendly}/>
                </div>
            </div>
        </div>
    )
}

export default JoinUs